import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

import Layout from 'components/layout'
import SEO from 'components/seo'
import FeatureSection from 'components/featureSection'
import HeroSection from 'components/heroSection'

const LandingPage = ({ className, data }) => {
  return (
    <Layout>
      <Background
        className={className}
        fluid={data.airpod.childImageSharp.fluid}
        style={{ position: '' }}
      />
      <SEO title="Home" />
      <HeroSection />
      <FeatureSection />
    </Layout>
  )
}

const Background = styled(BackgroundImage)`
  width: 100%;
  min-height: 95vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -20;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
`

export const pageQuery = graphql`
  query {
    airpod: file(relativePath: { eq: "airpod.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default LandingPage
