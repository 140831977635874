import React from 'react'
import { Flex, Box, Heading, Text, Button } from 'rebass/styled-components'

const HeroSection = () => (
  <Flex
    as="section"
    id="hero"
    alignItems="center"
    width={[1, 1, 0.7]}
    height="80vh">
    <Box>
      <Heading variant="caps" mb={2} fontSize={[6, 7, 7]}>
        Products that catch your attention
      </Heading>
      <Text as="p" fontSize={[3, 4, 4]} mb={3}>
        Welcome to the future of online shopping
      </Text>
      <Button as="a" href="/products">
        Explore →
      </Button>
    </Box>
  </Flex>
)

export default HeroSection
