import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Flex, Box } from 'rebass/styled-components'

const FeatureSection = ({ className }) => {
  const { fox, bird, racoon, owl, koala } = useStaticQuery(graphql`
    query {
      fox: file(relativePath: { eq: "fox.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      bird: file(relativePath: { eq: "bird.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      koala: file(relativePath: { eq: "koala.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      owl: file(relativePath: { eq: "owl.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      racoon: file(relativePath: { eq: "racoon.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Box as="section" pt={6}>
      <FeatureCard>
        <FeatureImage>
          <Img className={className} fixed={fox.childImageSharp.fixed} />
        </FeatureImage>
        <FeatureText>
          <h1>Open Source</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum neque,
            placeat aut officia corrupti at repellendus pariatur dolore, ullam
            soluta, perferendis similique autem. Ea harum doloribus velit
            deleniti assumenda eius!
          </p>
        </FeatureText>
      </FeatureCard>
      <FeatureCard reverse>
        <FeatureImage>
          <Img className={className} fixed={bird.childImageSharp.fixed} />
        </FeatureImage>
        <FeatureText>
          <h1>30-day free returns</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum neque,
            placeat aut officia corrupti at repellendus pariatur dolore, ullam
            soluta, perferendis similique autem. Ea harum doloribus velit
            deleniti assumenda eius!
          </p>
        </FeatureText>
      </FeatureCard>
      <FeatureCard>
        <FeatureImage>
          <Img className={className} fixed={koala.childImageSharp.fixed} />
        </FeatureImage>
        <FeatureText>
          <h1>100% Up-time</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum neque,
            placeat aut officia corrupti at repellendus pariatur dolore, ullam
            soluta, perferendis similique autem. Ea harum doloribus velit
            deleniti assumenda eius!
          </p>
        </FeatureText>
      </FeatureCard>
      <FeatureCard reverse>
        <FeatureImage>
          <Img className={className} fixed={owl.childImageSharp.fixed} />
        </FeatureImage>
        <FeatureText>
          <h1>Secure payments</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum neque,
            placeat aut officia corrupti at repellendus pariatur dolore, ullam
            soluta, perferendis similique autem. Ea harum doloribus velit
            deleniti assumenda eius!
          </p>
        </FeatureText>
      </FeatureCard>
      <FeatureCard>
        <FeatureImage>
          <Img className={className} fixed={racoon.childImageSharp.fixed} />
        </FeatureImage>
        <FeatureText>
          <h1>24/7 Customer Support</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum neque,
            placeat aut officia corrupti at repellendus pariatur dolore, ullam
            soluta, perferendis similique autem. Ea harum doloribus velit
            deleniti assumenda eius!
          </p>
        </FeatureText>
      </FeatureCard>
    </Box>
  )
}

const FeatureCard = ({ reverse, ...rest }) => (
  <Flex
    flexDirection={[
      'column',
      reverse ? 'row-reverse' : 'row',
      reverse ? 'row-reverse' : 'row',
    ]}
    mb={4}
    {...rest}
  />
)

const FeatureImage = props => (
  <Flex justifyContent="center" width={'300px'} mb={[4, 4, 0]} {...props} />
)

const FeatureText = props => (
  <Flex flexDirection="column" justifyContent="center" px={4} {...props} />
)

export default FeatureSection
